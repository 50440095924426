import { UserId } from "@preava/preava-prevent-api-grpc-web-js/ids_pb";
import { AdminClient } from "@preava/preava-prevent-api-grpc-web-js/gateway_grpc_web_pb";
import { getTokenSilently, logout, getMetadata, getEndpoint, enumToString, GRPC_ERROR } from "@/modules/v2/utilities";

const getApi = (host) => {
  let modifiedHost = host.replace('admin', 'api')
  return (location.host.includes(":8080")) ? process.env.VUE_APP_TEST_ENDPOINT : `https://${modifiedHost}`;
}

const PREFIX = '🔮ADAPTER⟹ACCOUNT';
// const ENDPOINT = getEndpoint();
const ENDPOINT = getApi(location.host);

export const getRole = async (traceId, userId) => {
  return new Promise(async (resolve, reject) => {

    console.log(`[${PREFIX}]: Activating user...`);

    const metadata = await getMetadata('activate', traceId);
    const request = new UserId();
    const gateway = new AdminClient(ENDPOINT, null, null);

    request.setValue(userId);

    gateway.getRole(request, metadata, async (err, res) => {
      let err_data = null;
      if (err) {
        console.error(`[${PREFIX}]: Backend returned and error: `, err);
        error(err);
        // If error happened during Activation, always logout and display the error page
        err_data = {
          type: 'support',
          message: err.message,
          details: err.details,
          code: err.code,
          status: enumToString(GRPC_ERROR, err.code),
          requestId: metadata['x-request-id'],
          correlationId: metadata['x-trace-id'],
        }
        logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
      } else {
        resolve(res)
      }
    }) // end of gateway
  }) // end of promise
} // end of activate() method
