import { Empty } from "@preava/preava-prevent-api-grpc-web-js/messages_pb";
import { Activation as ACTIVATION} from "@preava/preava-prevent-api-grpc-web-js/enums_pb";
import { AccountClient } from "@preava/preava-prevent-api-grpc-web-js/gateway_grpc_web_pb";
import { getTokenSilently, logout, getMetadata, getEndpoint, enumToString, GRPC_ERROR } from "@/utilities";

const getApi = (host) => {
  let modifiedHost = host.replace('admin', 'api')
  return (location.host.includes(":8080")) ? process.env.VUE_APP_TEST_ENDPOINT : `https://${modifiedHost}`;
}

const PREFIX = '🔮ADAPTER⟹ACCOUNT';
// const ENDPOINT = getEndpoint();
// const ENDPOINT = `https://api.dev.preava.tech`
const ENDPOINT = getApi(location.host)
// const ENDPOINT = `https://api.sandbox.preava.tech`
// const ENDPOINT = `https://api.dev.preava.tech`


export const activate = async (traceId) => {
  return new Promise(async (resolve, reject) => {

    console.log(ENDPOINT)
    console.log(traceId)
    console.log(`[${PREFIX}]: Activating user...`);

    const metadata = await getMetadata('activate', traceId);
    const request = new Empty();
    const gateway = new AccountClient(ENDPOINT, null, null);

    gateway.activate(request, metadata, async (err, res) => {
      let err_data = null;
      if (err) {
        console.error(`[${PREFIX}]: Backend returned and error: `, err);
        console.error(err);
        // If error happened during Activation, always logout and display the error page
        err_data = {
          type: 'support',
          message: err.message,
          details: err.details,
          code: err.code,
          status: enumToString(GRPC_ERROR, err.code),
          requestId: metadata['x-request-id'],
          correlationId: metadata['x-trace-id'],
        }
        logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
      } else {
        let response = res;

        console.log(`[${PREFIX}]: A response was received from the backend:`, response);
        /**
         * 
         * Depending on the Status returned, make sure to redirect the user
         * to the right Error Pages. 
         */
        switch (response.getStatus()) {
          case ACTIVATION.ACTIVATION_FAILED_ORG_NOT_FOUND:
            // Returned when an organization is unknown
            // i.e. contact sales to purchase an account
            // --> call to action: Contact Sales
            console.error(`[${PREFIX}]: Error occurred while activating user: ${enumToString(ACTIVATION, response.getStatus())}`);
            console.error(`[${PREFIX}]: Message: ${response.getBottle().getMessage().getValue()} , Details: ${response.GetBottle().getDetails().getValue()}`);
            err_data = {
              type: 'sales',
              code: response.getStatus(),
              status: `${enumToString(ACTIVATION, response.getStatus())}`,
              message: response.getBottle().getMessage().getValue(),
              details: response.getBottle().getDetails().getValue(),
              requestId: metadata['x-request-id'],
              correlationId: metadata['x-trace-id'],
            }
            logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
            break;

          case ACTIVATION.ACTIVATION_FAILED_ORG_NOT_ACTIVE:
            // Returned when an organization is known but inactive
            // i.e. contact admin to activate the account
            // --> call to action: Get Support
            console.error(`[${PREFIX}]: Error occurred while activating user: ${enumToString(ACTIVATION, response.getStatus())}`);
            console.error(`[${PREFIX}]: Message: ${response.getBottle().getMessage().getValue()} , Details: ${response.getBottle().getDetails().getValue()}`);
            err_data = {
              type: 'support',
              code: response.getStatus(),
              status: `${enumToString(ACTIVATION, response.getStatus())}`,
              message: response.getBottle().getMessage().getValue(),
              details: response.getBottle().getDetails().getValue(),
              requestId: metadata['x-request-id'],
              correlationId: metadata['x-trace-id'],
            }
            logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
            break;

          case ACTIVATION.ACTIVATION_FAILED_USER_NOT_INVITED:
            // Returned when an organization is known but the user is not invited
            // i.e. contact admin to invite you
            // --> call to action: Get Support
            console.error(`[${PREFIX}]: Error occurred while activating user: ${enumToString(ACTIVATION, response.getStatus())}`);
            console.error(`[${PREFIX}]: Message: ${response.getBottle().getMessage().getValue()} , Details: ${response.getBottle().getDetails().getValue()}`);
            err_data = {
              type: 'support',
              code: response.getStatus(),
              status: `${enumToString(ACTIVATION, response.getStatus())}`,
              message: response.getBottle().getMessage().getValue(),
              details: response.getBottle().getDetails().getValue(),
              requestId: metadata['x-request-id'],
              correlationId: metadata['x-trace-id'],
            }
            logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
            break;

          case ACTIVATION.ACTIVATION_FAILED_USER_NOT_SEATED:
            // Returned when the organization and the user are known, but no license
            // has been assigned to the user.
            // i.e. contact admin to assign a seat for you
            // --> call to action: Get Support
            console.error(`[${PREFIX}]: Error occurred while activating user: ${enumToString(ACTIVATION, response.getStatus())}`);
            console.error(`[${PREFIX}]: Message: ${response.getBottle().getMessage().getValue()} , Details: ${response.getBottle().getDetails().getValue()}`);
            err_data = {
              type: 'support',
              code: response.getStatus(),
              status: `${enumToString(ACTIVATION, response.getStatus())}`,
              message: response.getBottle().getMessage().getValue(),
              details: response.getBottle().getDetails().getValue(),
              requestId: metadata['x-request-id'],
              correlationId: metadata['x-trace-id'],
            }
            logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
            break;

          case ACTIVATION.ACTIVATION_FAILED_DEADLINE_TOO_SHORT:
            // Returned when the client sets a grpc-timeout header which is too short
            // The activation should be carried on with grpc-timeout=15s
            // --> call to action: Get Support
            console.error(`[${PREFIX}]: Error occurred while activating user: ${enumToString(ACTIVATION, response.getStatus())}`);
            console.error(`[${PREFIX}]: Message: ${response.getBottle().getMessage().getValue()} , Details: ${response.getBottle().getDetails().getValue()}`);
            err_data = {
              type: 'support',
              code: response.getStatus(),
              status: `${enumToString(ACTIVATION, response.getStatus())}`,
              message: response.getBottle().getMessage().getValue(),
              details: response.getBottle().getDetails().getValue(),
              requestId: metadata['x-request-id'],
              correlationId: metadata['x-trace-id'],
            }
            logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
            break;

          case ACTIVATION.ACTIVATION_FAILED_PROVISIONING_CONSISTENCY_NOT_GUARANTEED:
            // Returned when activate service cannot guarantee that the provisioning
            // has been executed correctly within a grpc-timeout >= 15s
            // In this specific case, the client should retry to activate 3 times
            // informing the user.
            // The ideal grpc-timeout is set to 15s on the current servers.
            // i.e. our servers are overloaded, please retry later
            // --> call to action: Get Support
            console.error(`[${PREFIX}]: Error occurred while activating user: ${enumToString(ACTIVATION, response.getStatus())}`);
            console.error(`[${PREFIX}]: Message: ${response.getBottle().getMessage().getValue()} , Details: ${response.getBottle().getDetails().getValue()}`);
            err_data = {
              type: 'support',
              code: response.getStatus(),
              status: `${enumToString(ACTIVATION, response.getStatus())}`,
              message: response.getBottle().getMessage().getValue(),
              details: response.getBottle().getDetails().getValue(),
              requestId: metadata['x-request-id'],
              correlationId: metadata['x-trace-id'],
            }
            logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
            break;

          case ACTIVATION.ACTIVATION_FAILED_AUTHORIZATION_CONSISTENCY_REESTABLISHED:
            // ✅ When a user activates the account, Activate service sets the Auth0 Role
            // according to the prv_enums_v2.Role found in the User DB.
            // Consistency between the User directory and Auth0 is guaranteed by our code.
            // Although our code is awersome and we have tests, might always happen that
            // the Role in the User directory does not match the Role in Auth0.
            // This might be caused by a user with admin access to the Auth0 tenant who
            // decides to remove a User role form Auth0. As a client:
            // - upon receiving a CONSISTENCY_REESTABILISHED message...
            // - ... force a token refresh on the Auth0 SPA library
            // - and continue the flow accoridngly
            // The extra quality-prone developer would:
            // - activate one more time expecting ACTIVATION_ACTIVATED...
            // - ... and panic with an un-recoverable error if CONSISTENCY_REESTABILISHED
            // is returned In fact, it might happen that our code is buggy and it is
            // repetitively failing in performing the authorization alignment between
            // Auth0 and the User DB.
            // 🚩 NOTE: this is not yet implemented!!! ETA end of July 2022
            // --> call to action: Get Support
            console.error(`[${PREFIX}]: Error occurred while activating user: ${enumToString(ACTIVATION, response.getStatus())}`);
            console.error(`[${PREFIX}]: Message: ${response.getBottle().getMessage().getValue()} , Details: ${response.getBottle().getDetails().getValue()}`);
            err_data = {
              type: 'support',
              code: response.getStatus(),
              status: `${enumToString(ACTIVATION, response.getStatus())}`,
              message: response.getBottle().getMessage().getValue(),
              details: response.getBottle().getDetails().getValue(),
              requestId: metadata['x-request-id'],
              correlationId: metadata['x-trace-id'],
            }
            logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
            break;

          case ACTIVATION.ACTIVATION_ACTIVATED:
            // ✅ Returned upon successful activation
            // - all DBs/user-data provisioned
            // - all required API scopes/roles attached to the user
            // - the system is usable
            // - the system can be de-activated again if Dectivate() RPC is called
            // --> call to action: none, continue to application
            console.log(`[${PREFIX}]: Status Response Received. [${enumToString(ACTIVATION, response.getStatus())}]`);
            console.log(`[${PREFIX}]: Message: ${response.getBottle().getMessage().getValue()}. Details: ${response.getBottle().getDetails().getValue()}`);
            resolve(response)
            break;

          case ACTIVATION.ACTIVATION_ACTIVATED_WITH_NEW_CREDENTIALS:
            // ✅ When a user activates the account for the first time, a new JWT token
            // needs to be requested.
            // --> call to action: none, continue to application
            console.log(`[${PREFIX}]: Status Response Received. Refreshing Token. [${enumToString(ACTIVATION, response.getStatus())}]`);
            console.log(`[${PREFIX}]: ${response.getBottle().getMessage().getValue()}. ${response.getBottle().getDetails().getValue()}`);
            await getTokenSilently({ ignoreCache: true })
            resolve(response)
            break;

          default:
            console.error(`[${PREFIX}]: Unknown Status Received.`);
            err_data = {
              type: 'support',
              code: response.getStatus(),
              status: `${enumToString(ACTIVATION, response.getStatus())}`,
              message: response.getBottle().getMessage().getValue(),
              details: response.getBottle().getDetails().getValue(),
              requestId: metadata['x-request-id'],
              correlationId: metadata['x-trace-id'],
            }
            logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
            break;
        }
      }
    }) // end of gateway
  }) // end of promise
} // end of activate() method


export const deactivate = async (traceId) => {
  return new Promise(async (resolve, reject) => {

    /**
     * NOTE: Here, we don't reject the promise for UI to display error.
     * This is because we always logout the user every time 
     * an issue is encountered during deactivation
     */

    console.log(`[${PREFIX}]: Deactivating user...`);

    const metadata = await getMetadata('deactivate', traceId);
    const request = new Empty();
    const gateway = new AccountClient(ENDPOINT, null, null);

    gateway.deactivate(request, metadata, async (err, res) => {
      let err_data = null;
      if (err) {
        console.error(`[${PREFIX}]: Error occurred while deactivating user.`);
        console.error(err);
        // If error happened during Deactivation, always logout and display the error page
        err_data = {
          type: 'support',
          message: err.message,
          details: err.details,
          code: err.code,
          status: enumToString(GRPC_ERROR, err.code),
          requestId: metadata['x-request-id'],
          correlationId: metadata['x-trace-id'],
        }
        logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
      } else {
        let response = res;
        console.log(`[${PREFIX}]: A response was received from the backend:`, response);
        console.log(`[${PREFIX}]: `, { response });
        /**
         * 
         * Depending on the Status returned, make sure to redirect the user
         * to the right Error Pages. 
         */
        switch (response.getStatus()) {
          case ACTIVATION.ACTIVATION_DEACTIVATED:
            console.log(`[${PREFIX}]: Status Response Received.`);
            console.log(`[${PREFIX}]: ${response.getBottle().getMessage().getValue()}. ${response.getBottle().getDetails().getValue()}`);
            resolve(response)
            break;
          default:
            console.error(`[${PREFIX}]: Unknown Status Received.`);
            err_data = {
              type: 'support',
              message: response.getBottle().getMessage().getValue(),
              details: response.getBottle().getDetails().getValue(),
              code: 'N/A',
              status: 'N/A',
              requestId: metadata['x-request-id'],
              correlationId: metadata['x-trace-id'],
            }
            logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
            break;
        }
      }
    }) // end of gateway
  }) // end of promise
} // end of deactivate() method