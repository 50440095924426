import { Empty } from "@preava/preava-prevent-api-grpc-web-js/messages_pb";
import { UserClient } from "@preava/preava-prevent-api-grpc-web-js/gateway_grpc_web_pb";
import { logout, getMetadata, enumToString, getEndpoint, GRPC_ERROR } from "@/utilities";

const getApi = (host) => {
  let modifiedHost = host.replace('admin', 'api')
  return (location.host.includes(":8080")) ? process.env.VUE_APP_TEST_ENDPOINT : `https://${modifiedHost}`;
}

const PREFIX = '🔮ADAPTER⟹USER';
// const ENDPOINT = getEndpoint();
const ENDPOINT = getApi(location.host);

export const getIdentity = async (traceId) => {
  return new Promise(async (resolve, reject) => {

    console.log(`[${PREFIX}]: Retreiving identity from the backend...`);

    const metadata = await getMetadata(null, traceId); // null=default request type
    const request = new Empty();
    const gateway = new UserClient(ENDPOINT, null, null);
    
    gateway.getMyIdentity(request, metadata, (err, res) => {
      let err_data = null;
      if (err) {
        console.error(`[${PREFIX}]: Backend returned and error: `, err);
        if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
          // If user is unauthenticated, always logout. 
          // Otherwise, return the error to the UI for display
          err_data = {
            type: 'support',
            message: err.message,
            details: err.details,
            code: err.code,
            status: enumToString(GRPC_ERROR, err.code),
            requestId: metadata['x-request-id'],
            correlationId: metadata['x-trace-id'],
          }
          logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
        } else {
          reject(err);
        }
      } else {
        console.log(`[${PREFIX}]: A response was received from the backend:`, res);
        resolve(res)
      }
    }) // end of gateway
  }) // end of promise
} // end of getIdentity() method
