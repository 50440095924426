// import { RuleClient } from "@preava/preava-prevent-api-grpc-web-js/gateway_grpc_web_pb";\
// import { RuleClient } from "prv-api/pkg/web-js/rule_grpc_web_pb";
import { logout, getMetadata, getEndpoint, enumToString, GRPC_ERROR } from "@/utilities";
// import { OrgId, OrganizationB2B, OrganizationItemB2B, ObjectId } from "prv-api/pkg/web-js/ids_pb";
// import { RuleData, RulePayload } from "prv-api/pkg/web-js/rule_pb";
// import { Service } from "prv-api/pkg/web-js/types_pb";

const getApi = (host) => {
  let modifiedHost = host.replace('admin', 'api')
  return (location.host.includes(":8080")) ? process.env.VUE_APP_TEST_ENDPOINT : `https://${modifiedHost}`;
}
const ENDPOINT = getApi(location.host);

const PREFIX = '🔮ADAPTER⟹RULES';
// const ENDPOINT = getEndpoint();

export const listRule = async (traceId, _orgId) => {
  return new Promise(async (resolve, reject) => {

    console.log(`[${PREFIX}]: Retreiving all rules from the backend...`);
    console.log(_orgId)

    const metadata = await getMetadata(null, traceId); // null=default request type
    const orgId = new OrgId();
    orgId.setValue(_orgId)
    

    const request = new OrganizationB2B();
    request.setOrgId(orgId)
    
    const gateway = new RuleClient(ENDPOINT, null, null);
    const stream = gateway.listRule(request, metadata);

    var _rules = [];
    console.log(`[${PREFIX}]: The rule client stream is now starting.`);
    
    /**
     * Stream received data object
     */
    stream.on("data", function (res) {
      console.log(`[${PREFIX}]: A rule client stream response was received from the backend:`, res);
      _rules.push(res)
    });

    /**
     * Stream received an error object
     */
    stream.on("error", function (err) {
      let err_data = null;
      console.error(`[${PREFIX}]: A rule client stream error was returned:`, err);
      stream.cancel();
      if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
        // If user is unauthenticated, always logout. 
        // Otherwise, return the error to the UI for display
        err_data = {
          type: 'support',
          message: err.message,
          details: err.details,
          code: err.code,
          status: enumToString(GRPC_ERROR, err.code),
          requestId: metadata['x-request-id'],
          correlationId: metadata['x-trace-id'],
        }
        logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
      } else {
        reject(err);
      }
    });

    /**
     * Stream has ended
     */
    stream.on("end", () => {
      console.log(`[${PREFIX}]: The rule client stream ended.`);
      stream.cancel();
      resolve(_rules)
    });

  }) // end of promise
}

/**
 * Update a rule here
 */
export const updateRule = async (traceId, _orgId, rule) => {
  return new Promise(async (resolve, reject) => {

    const metadata = await getMetadata(null, traceId);

    const rulePayload = new RulePayload();

    const orgId = new OrgId();
    const objectId = new ObjectId();
    const policyId = new ObjectId();
    const organizationItemB2B = new OrganizationItemB2B();

    orgId.setValue(_orgId)
    objectId.setValue(rule.getItem().getObjectId().getValue())
    organizationItemB2B.setOrgId(orgId)
    organizationItemB2B.setObjectId(objectId)
    
    // Set the Rule Payload item
    rulePayload.setItem(organizationItemB2B)

    const ruleData = new RuleData();
    ruleData.setName(rule.getData().getName())
    ruleData.setPolicyId(policyId.setValue(rule.getData().getPolicyId().getValue()))
    ruleData.setPriority(rule.getData().getPriority())
    ruleData.setStatus(rule.getData().getStatus())
    ruleData.setLogging(rule.getData().getLogging())

    /**
     * Set new Service Provision
     */
    let service_provision = new Service();
    service_provision.setName(rule.getData().getServiceProvision().getName())
    service_provision.setVersion(rule.getData().getServiceProvision().getVersion())
    ruleData.setServiceProvision(service_provision)

    /**
     * Implementation starts here
     */
    
    

    // get all the implementation cases from the rule data protobuf as keys
    Object.keys(RuleData.ImplementationCase).forEach( implementation => {
      // find the active implementation
      let _rule = rule.toObject();
      if(_rule.data[implementation.toLowerCase()]){  
        let funcName = implementation.charAt(0).toUpperCase() + implementation.slice(1).toLowerCase(); // derive the name of the ruleData function from the implementation
        let theData = rule.getData(); // get the data object
        let activeImplementation = theData[`get${funcName}`](); // get the active implementation from the data
        ruleData[`set${funcName}`](activeImplementation); // set this back as the ruleData implementation
      }
    });
    
    // Finally the Rule Payload data
    rulePayload.setData(ruleData)
    
    const gateway = new RuleClient(ENDPOINT, null, null);
    
    gateway.updateRule(rulePayload, metadata, (err, res) => {

      let err_data = null;
      if (err) {
        console.error(`[${PREFIX}]: Error occurred while updating role`);
        console.error(err);
        if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
          // If user is unauthenticated, always logout. 
          // Otherwise, return the error to the UI for display
          err_data = {
            type: 'support',
            message: err.message,
            details: err.details,
            code: err.code,
            status: enumToString(GRPC_ERROR, err.code),
            requestId: metadata['x-request-id'],
            correlationId: metadata['x-trace-id'],
          }
          logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
        } else {
          reject(err);
        }
      } else {
        console.log(`[${PREFIX}]: A response was received from the backend:`, res);
        console.log(`[${PREFIX}]: Updating rule was successful.`);
        resolve(true);
      }
    })

    

    
  }) // end of promise
} // end of getIdentity() method

/**
 * Update a rule here
 */
export const deleteRule = async (traceId, _orgId, rule) => {
  return new Promise(async (resolve, reject) => {

    const metadata = await getMetadata(null, traceId);
    const orgId = new OrgId();
    const objectId = new ObjectId();
    
    const request = new OrganizationItemB2B();

    orgId.setValue(_orgId)
    objectId.setValue(rule.getItem().getObjectId().getValue())
    request.setOrgId(orgId)
    request.setObjectId(objectId)
    
    const gateway = new RuleClient(ENDPOINT, null, null);
    
    gateway.deleteRule(request, metadata, (err, res) => {

      let err_data = null;
      if (err) {
        console.error(`[${PREFIX}]: Error occurred while deleting role`);
        console.error(err);
        if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
          // If user is unauthenticated, always logout. 
          // Otherwise, return the error to the UI for display
          err_data = {
            type: 'support',
            message: err.message,
            details: err.details,
            code: err.code,
            status: enumToString(GRPC_ERROR, err.code),
            requestId: metadata['x-request-id'],
            correlationId: metadata['x-trace-id'],
          }
          logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
        } else {
          reject(err);
        }
      } else {
        console.log(`[${PREFIX}]: A response was received from the backend:`, res);
        console.log(`[${PREFIX}]: Deleting rule was successful.`);
        resolve(true);
      }
    })

    

    
  }) // end of promise
} // end of getIdentity() method

/**
 * Update a rule here
 */
export const createRule = async (traceId, _orgId, rule) => {
  return new Promise(async (resolve, reject) => {

    const metadata = await getMetadata(null, traceId);

    const rulePayload = new RulePayload();

    const orgId = new OrgId();
    const objectId = new ObjectId();
    const policyId = new ObjectId();
    const organizationItemB2B = new OrganizationItemB2B();

    orgId.setValue(_orgId)
    objectId.setValue(rule.getItem().getObjectId().getValue())
    organizationItemB2B.setOrgId(orgId)
    organizationItemB2B.setObjectId(objectId)
    
    // Set the Rule Payload item
    rulePayload.setItem(organizationItemB2B)

    const ruleData = new RuleData();
    ruleData.setName(rule.getData().getName())
    ruleData.setPolicyId(policyId.setValue(rule.getData().getPolicyId().getValue()))
    ruleData.setPriority(rule.getData().getPriority())
    ruleData.setStatus(rule.getData().getStatus())
    ruleData.setLogging(rule.getData().getLogging())

    /**
     * Set new Service Provision
     */
    let service_provision = new Service();
    service_provision.setName(rule.getData().getServiceProvision().getName())
    service_provision.setVersion(rule.getData().getServiceProvision().getVersion())
    ruleData.setServiceProvision(service_provision)

    /**
     * Implementation starts here
     */
    
    

    // get all the implementation cases from the rule data protobuf as keys
    Object.keys(RuleData.ImplementationCase).forEach( implementation => {
      // find the active implementation
      let _rule = rule.toObject();
      if(_rule.data[implementation.toLowerCase()]){  
        let funcName = implementation.charAt(0).toUpperCase() + implementation.slice(1).toLowerCase(); // derive the name of the ruleData function from the implementation
        let theData = rule.getData(); // get the data object
        let activeImplementation = theData[`get${funcName}`](); // get the active implementation from the data
        ruleData[`set${funcName}`](activeImplementation); // set this back as the ruleData implementation
      }
    });
    
    // Finally the Rule Payload data
    rulePayload.setData(ruleData)
    
    const gateway = new RuleClient(ENDPOINT, null, null);
    
    gateway.updateRule(rulePayload, metadata, (err, res) => {

      let err_data = null;
      if (err) {
        console.error(`[${PREFIX}]: Error occurred while updating role`);
        console.error(err);
        if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
          // If user is unauthenticated, always logout. 
          // Otherwise, return the error to the UI for display
          err_data = {
            type: 'support',
            message: err.message,
            details: err.details,
            code: err.code,
            status: enumToString(GRPC_ERROR, err.code),
            requestId: metadata['x-request-id'],
            correlationId: metadata['x-trace-id'],
          }
          logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
        } else {
          reject(err);
        }
      } else {
        console.log(`[${PREFIX}]: A response was received from the backend:`, res);
        console.log(`[${PREFIX}]: Updating rule was successful.`);
        resolve(true);
      }
    })

    

    
  }) // end of promise
} // end of getIdentity() method
